import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from '@sanity/block-content-to-react';
import * as styles from './ProductList.module.scss';
import Hero from '../Hero/Hero';
import Wrapper from '../Wrapper/Wrapper';

const ProductList = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          allSanityProducts {
            nodes {
              ...ProductsData
            }
          }
          sanityTexts(_id: { eq: "texts" }) {
            _rawProducts(resolveReferences: { maxDepth: 10 })
          }
        }
      `}
      render={(data) => {
        const text = data.sanityTexts._rawProducts;
        const productList = data.allSanityProducts.nodes.map(
          ({ id, title, price, active, profitt, _rawDescription, image }) => {
            if (active === false) return null;
            return (
              <div key={id} className={styles.product}>
                <GatsbyImage
                  className={styles.image}
                  image={image.asset.gatsbyImageData}
                  alt={`${image.alt}`}
                />
                <h2 className={styles.name}>{title}</h2>
                <div className={styles.description}>
                  <PortableText blocks={_rawDescription} />
                </div>
                <div className={styles.pricing}>
                  <div className={styles.profit}>+{profitt}</div>
                  <div className={styles.disclaimer}>
                    Dere betaler {price} og selger for {price + profitt}
                  </div>
                </div>
              </div>
            );
          }
        );
        return (
          <div className={styles.products}>
            <Hero block={text} />
            <Wrapper>
              <div className={styles.productGrid}>{productList}</div>
            </Wrapper>
          </div>
        );
      }}
    />
  );
};

export default ProductList;
