// extracted by mini-css-extract-plugin
export var background = "Hero-module--background--ATIAV";
export var blue = "Hero-module--blue--fj9C5";
export var content = "Hero-module--content--ZUwe4";
export var dark = "Hero-module--dark--wXxZH";
export var form = "Hero-module--form--gC52E";
export var hero = "Hero-module--hero--52veU";
export var image = "Hero-module--image--tSPhZ";
export var large = "Hero-module--large--77U0q";
export var pink = "Hero-module--pink--v3xN+";
export var text = "Hero-module--text--w1SmR";