import React, { useState } from 'react';
import * as styles from './ContactForm.module.scss';
import Button from '../Button/Button';
import Input from '../Input/Input';
import PropTypes from 'prop-types';
import { CheckCircleOutlineOutlined } from '@material-ui/icons';
import handleSubmit from '../../utils/handleSubmit';

const ContactForm = ({ small, askType }) => {
  const [submitted, setSubmitted] = useState(false);

  return (
    <div className={styles.contact}>
      {submitted === false ? (
        <form
          className={styles.form}
          method="POST"
          name={'contact'}
          data-netlify="true"
          onSubmit={(event) => {
            handleSubmit(event, setSubmitted(true));
          }}
        >
          <input type="hidden" name="form-name" value="contact" />
          <div className={styles.title}>Få et uforpliktende tilbud</div>
          <div className={styles.input}>
            <Input placeholder={'Navn'} name={'navn'} />
          </div>
          <div className={styles.input}>
            <Input placeholder={'Telefonnummer'} name={'telefon'} />
          </div>
          {!small && (
            <div className={styles.input}>
              <Input placeholder={'E-post'} name={'epost'} />
            </div>
          )}
          {askType && (
            <div className={styles.input}>
              <Input placeholder={'Russ / Idrettslag / Skole?'} name={'type'} />
            </div>
          )}
          {!askType && !small && (
            <div className={styles.input}>
              <Input placeholder={'Kort om dere'} name={'about'} />
            </div>
          )}
          <div className={styles.button}>
            <Button variant={'secondary'}>Kontakt meg</Button>
          </div>
        </form>
      ) : (
        <div className={styles.confirmation}>
          <div className={styles.icon}>
            <CheckCircleOutlineOutlined className={styles.icon} />
          </div>
          <h2>Takk for din registrering!</h2>
          <p>Noen vil ta kontakt med deg innen kort tid.</p>
        </div>
      )}
    </div>
  );
};

ContactForm.propTypes = {
  small: PropTypes.bool,
  askType: PropTypes.bool,
};

ContactForm.defaultProps = {
  small: false,
  askType: false,
};

export default ContactForm;
