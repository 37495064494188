import * as React from 'react';
import Layout from '../components/Layout/Layout';
import ProductList from '../components/ProductList/ProductList';

const Products = () => {
  return (
    <Layout>
      <ProductList />
    </Layout>
  );
};

export default Products;
