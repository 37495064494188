import React from 'react';
import * as styles from './Hero.module.scss';
import PortableText from '@sanity/block-content-to-react';
import Wrapper from '../Wrapper/Wrapper';
import Button from '../Button/Button';
import ContactForm from '../ContactForm/ContactForm';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';

const Hero = ({ maximize, background, theme, showForm, block }) => {
  return (
    <div
      className={classNames(styles.hero, styles[theme], {
        [styles.large]: maximize === true,
      })}
    >
      {background !== null && (
        <div className={styles.background}>
          <GatsbyImage
            className={styles.image}
            image={background}
            alt={`Bakgrunndsbilde`}
          />
        </div>
      )}
      <Wrapper>
        <div
          className={classNames(styles.content, {
            [styles.form]: showForm === true,
          })}
        >
          <div className={styles.text}>
            <PortableText blocks={block} />
            <Button to={'/bestill'}>Bestill nå</Button>
          </div>
          {showForm && <ContactForm small={true} />}
        </div>
      </Wrapper>
    </div>
  );
};

Hero.propTypes = {
  maximize: PropTypes.bool,
  background: PropTypes.object,
  theme: PropTypes.string,
  showForm: PropTypes.bool,
  block: PropTypes.array.isRequired,
};

Hero.defaultProps = {
  maximize: false,
  background: null,
  theme: 'blue',
  showForm: false,
  block: null,
};

export default Hero;
